'use strict'
const _ = require('lodash')
const coreUtilsLib = require('santa-core-utils')
const PropTypes = require('prop-types')
const widgetService = require('./widgetService')
const RemoteWidgetHandlerProxy = require('./RemoteWidgetHandlerProxy')
const widgetModel = require('../utils/widgetModel')
const ActionQueue = require('../utils/ActionQueue')
const modelBuilderDataHelper = require('./modelBuilderDataHelper')
const widgetDataResolvers = require('./widgetDataResolvers')
const wixCodePostMessagesHandler = require('./wixCodePostMessagesHandler')
const wixCodeMessageModifier = require('../messages/wixCodeMessageModifier')

const privatesMap = new WeakMap()

// Readiness
function getAppsContextId(rootId, loadedAppsRootIds) {
    const privates = privatesMap.get(this)

    if (rootId !== coreUtilsLib.siteConstants.MASTER_PAGE_ID) {
        return rootId
    }

    return _.find(loadedAppsRootIds, widgetId => {
        const rootData = privates.aspectProps.getDataByQuery(widgetId)
        return modelBuilderDataHelper.getWidgetType(rootData) === modelBuilderDataHelper.WIDGET_TYPES.PAGE
    })
}

function createAndRegisterWidgetHandler(rendererServices, updateSite, flushWidgetReady, wixCodeAppApi, wixCodePostMessagesProps, deferWixCodeMessagesRegistration) {
    const privates = privatesMap.get(this)

    const widgetHandler = new RemoteWidgetHandlerProxy(rendererServices, updateSite, flushWidgetReady)
    privates.widgetHandler = widgetHandler

    if (!deferWixCodeMessagesRegistration) {
        privates.isRegisteredToWixCodeMessages = true
        wixCodePostMessagesHandler.registerWixCodeMessageHandler({
            hostProps: wixCodePostMessagesProps,
            remoteMessagesHandler: widgetHandler,
            wixCodeAppApi
        })
    }
    privatesMap.set(this, privates)

    return widgetHandler
}

function handleRuntimeDalCompChange(compId, changeObject) {
    const privates = privatesMap.get(this)

    const changeToValueKey = {
        dataChange: 'data',
        propsChange: 'props',
        stateChange: 'state',
        layoutChange: 'layout'
    }
    const compUpdates = _.zipObject([changeToValueKey[changeObject.type]], [changeObject.value])
    const modelUpdates = _.zipObject([compId], [compUpdates])

    privates.widgetHandler.handleWidgetUpdate(modelUpdates)
}

function getUserCodeDefinitions(contextIds) {
    return _.map(contextIds, contextId => ({
        displayName: `Page: ${contextId}`,
        id: contextId,
        type: 'Page'
    }))
}

class WidgetAspect {
    // TODO remove default storageAPI parameter when WidgetAspect is moved to host libs
    constructor(hostProps, storageAPI = new Map(), eventsManager = {on: _.noop}, runtimeDal, actionQueue = new ActionQueue(), wixCodeAppApi, skipUpdateCallback) {
        hostProps = _.assign({}, WidgetAspect.defaultProps, hostProps)
        privatesMap.set(this, {
            storageAPI,
            eventsManager,
            runtimeDal,
            actionQueue,
            wixCodeAppApi,
            aspectProps: hostProps,
            updateSiteCallBacks: [],
            onWidgetReady: {},
            updating: false,
            widgetHandler: {},
            contextIdsToIgnore: []
        })

        storageAPI.set('loadedAppsRoots', [])

        // TODO extract event names to constants
        eventsManager.on('runtimeCompChange', handleRuntimeDalCompChange.bind(this))

        const handlerServices = {
            runtimeDal,
            actionQueue,
            wixCodeAppApi,
            storageAPI,
            aspectProps: hostProps
        }
        const updateSiteCallback = skipUpdateCallback ? _.noop : this.updateSite.bind(this)
        createAndRegisterWidgetHandler.call(
            this,
            handlerServices,
            updateSiteCallback,
            this.flushWidgetReady.bind(this),
            wixCodeAppApi,
            hostProps.wixCodePostMessagesProps,
            hostProps.deferWixCodeMessagesRegistration)
    }

    onHostPropsChanged(nextProps) {
        const privates = privatesMap.get(this)
        privates.aspectProps = nextProps
    }

    updateSite(cb) {
        const privates = privatesMap.get(this)

        if (cb) {
            privates.updateSiteCallBacks.push(cb)
        }

        if (!privates.updating) {
            privates.updating = true

            coreUtilsLib.animationFrame.request(() => {
                _.invokeMap(privates.updateSiteCallBacks, _.call)

                privates.updating = false
                privates.updateSiteCallBacks = []
            })
        }
    }

    //remove when merging sv_handleFailingWixCodeSdk
    allContextsReady() {
        const privates = privatesMap.get(this)
        const loadedAppsRoots = privates.storageAPI.get('loadedAppsRoots')

        return _(loadedAppsRoots)
            .map('rootId')
            .every(rootId => this.isContextReady(rootId))
    }

    allContextsReadyOrLifecycleFailed() {
        const privates = privatesMap.get(this)
        const loadedAppsRoots = privates.storageAPI.get('loadedAppsRoots')

        return _(loadedAppsRoots)
            .map('rootId')
            .every(rootId => this.isContextReadyOrLifeCycleFailed(rootId))
    }

    //remove when merging sv_handleFailingWixCodeSdk
    isContextReady(rootId) {
        const privates = privatesMap.get(this)
        if (privates.aspectProps.isFirstRenderAfterSSR()) {
            return true
        }

        const renderedRootIds = privates.aspectProps.getAllPossiblyRenderedContexts()
        const loadedAppsRoots = privates.storageAPI.get('loadedAppsRoots')
        const loadedAppsRootIds = _.map(loadedAppsRoots, 'rootId')
        const appsContextId = getAppsContextId.call(this, rootId, loadedAppsRootIds)

        if (_.isEmpty(loadedAppsRoots) || !_.includes(renderedRootIds, rootId)) {
            return true
        }

        return _.includes(loadedAppsRootIds, appsContextId) && privates.widgetHandler.isWidgetReady(appsContextId)
    }

    isContextReadyOrLifeCycleFailed(rootId) {
        const privates = privatesMap.get(this)
        if (privates.aspectProps.isFirstRenderAfterSSR()) {
            return true
        }

        const renderedRootIds = privates.aspectProps.getAllPossiblyRenderedContexts()
        const loadedAppsRoots = privates.storageAPI.get('loadedAppsRoots')
        const loadedAppsRootIds = _.map(loadedAppsRoots, 'rootId')
        const appsContextId = getAppsContextId.call(this, rootId, loadedAppsRootIds)

        if (_.isEmpty(loadedAppsRoots) || !_.includes(renderedRootIds, rootId)) {
            return true
        }

        return _.includes(loadedAppsRootIds, appsContextId) && (privates.widgetHandler.isWidgetReady(appsContextId) || privates.widgetHandler.isWidgetFailed(appsContextId))
    }

    loadApps(contextIds, pagesInfo) {
        const privates = privatesMap.get(this)
        const loadedAppsRoots = privates.storageAPI.get('loadedAppsRoots')

        privates.storageAPI.set('loadedAppsRoots', widgetService.loadApps(privates.aspectProps.getApplicationsForContexts, loadedAppsRoots, contextIds, privates.widgetHandler, pagesInfo))
    }

    loadUserCode(contextIds) {
        const privates = privatesMap.get(this)
        if (!_.isEmpty(contextIds)) {
            privates.widgetHandler.loadUserCode(privates.aspectProps.getUserCodeDefinitions(contextIds), contextIds)
        }
    }

    triggerAppStudioWidgetOnPropsChanged(pageId, oldProps, newProps) {
        const privates = privatesMap.get(this)
        widgetService.triggerAppStudioWidgetOnPropsChanged(pageId, oldProps, newProps, privates.widgetHandler)
    }

    initApps(rootIds) {
        const privates = privatesMap.get(this)
        if (!privates.aspectProps.initWixCode) {
            return
        }

        widgetService.initApps(rootIds, privates.widgetHandler, privates.aspectProps.getContextInitData)
    }

    startApps(rootIds) {
        const privates = privatesMap.get(this)
        privates.widgetHandler.startWidgets(rootIds)
    }

    stopApps(rootIds) {
        const privates = privatesMap.get(this)
        const loadedAppsRoots = privates.storageAPI.get('loadedAppsRoots')

        privates.storageAPI.set('loadedAppsRoots', widgetService.stopApps(loadedAppsRoots, rootIds, privates.widgetHandler, privates.aspectProps.getAllRenderedContextIds))
    }

    restartApps() {
        const privates = privatesMap.get(this)
        const loadedAppsRoots = privates.storageAPI.get('loadedAppsRoots')
        if (_.isEmpty(loadedAppsRoots)) {
            return
        }

        const rootIds = _.map(loadedAppsRoots, 'rootId')
        this.stopApps(rootIds)
        this.loadApps(rootIds)
        this.initApps(rootIds)
    }

    registerToOnWidgetReady(rootId, callback) {
        if (!_.isFunction(callback)) {
            return
        }

        const privates = privatesMap.get(this)
        const isContextReadyOrLifeCycleFailed = privates.aspectProps.isExperimentOpen('sv_handleFailingWixCodeSdk') ? this.isContextReadyOrLifeCycleFailed(rootId) : this.isContextReady(rootId)
        if (isContextReadyOrLifeCycleFailed) {
            callback()
            return
        }

        if (!privates.onWidgetReady[rootId]) {
            privates.onWidgetReady[rootId] = []
        }

        privates.onWidgetReady[rootId].push(callback)
    }

    flushWidgetReady(rootId) {
        const privates = privatesMap.get(this)
        const callbacks = privates.onWidgetReady[rootId]
        _.forEach(callbacks, callback => {
            callback()
        })

        delete privates.onWidgetReady[rootId]
    }

    syncAppsState(loadedAppsRoots) {
        const privates = privatesMap.get(this)
        const fetchers = _.pick(privates.aspectProps, [
            'getContextIdsWhichShouldBeRendered',
            'filterValidContextsForLoad',
            'filterContextsToSync',
            'filterContextsToActivate',
            'getContextInitData',
            'getApplicationsForContexts'
        ])
        loadedAppsRoots = loadedAppsRoots || privates.storageAPI.get('loadedAppsRoots')

        loadedAppsRoots = _.reject(loadedAppsRoots, rootObj => _.includes(privates.contextIdsToIgnore, rootObj.rootId))

        loadedAppsRoots = widgetService.syncAppsState(
            privates.aspectProps.initWixCode,
            fetchers,
            loadedAppsRoots,
            privates.widgetHandler
        )
        privates.storageAPI.set('loadedAppsRoots', loadedAppsRoots)
        return loadedAppsRoots
    }

    getWidgetHandler() {
        const privates = privatesMap.get(this)
        return privates.widgetHandler
    }

    getLoadedAppsContexts() {
        const privates = privatesMap.get(this)
        return privates.storageAPI.get('loadedAppsRoots')
    }

    getCompModel(runtimeDal, compId, contextId) {
        const privates = privatesMap.get(this)

        return widgetModel.getCompModel({runtimeDal, compId, contextId, getCompReactClass: privates.aspectProps.getCompReactClass})
    }

    setContextIdToIgnore(contextId) {
        const privates = privatesMap.get(this)
        privates.contextIdsToIgnore = _.union(privates.contextIdsToIgnore, [contextId])
        privatesMap.set(this, privates)
    }

    removeContextIdToIgnore(contextId) {
        const privates = privatesMap.get(this)
        privates.contextIdsToIgnore = _.filter(privates.contextIdsToIgnore, id => id !== contextId)
        privatesMap.set(this, privates)
    }

    registerWixCodePostMessageModifier(modifier) {
        const privates = privatesMap.get(this)
        wixCodePostMessagesHandler.registerMessageModifier(privates.wixCodeAppApi, modifier)
    }

    extendPostMessagesWithUserCodeInfo(wixCodeScriptUrl) {
        function modifyPostMessage(messageData) {
            return wixCodeMessageModifier.getExtendedMessage(messageData, wixCodeScriptUrl)
        }
        this.registerWixCodePostMessageModifier(modifyPostMessage)
    }

    sendDelayedWixCodePostMessage(message, handler, props) {
        const privates = privatesMap.get(this)

        wixCodePostMessagesHandler.onMessage({
            remoteMessagesHandler: privates.widgetHandler,
            hostProps: props || privates.aspectProps.wixCodePostMessagesProps
        }, message, handler)
    }

    registerToWixCodePostMessages(props) {
        const privates = privatesMap.get(this)

        if (!privates.isRegisteredToWixCodeMessages) {
            wixCodePostMessagesHandler.registerWixCodeMessageHandler({
                hostProps: props,
                remoteMessagesHandler: privates.widgetHandler,
                wixCodeAppApi: privates.wixCodeAppApi
            })
            privates.isRegisteredToWixCodeMessages = true
            privatesMap.set(this, privates)
        } else {
            throw new Error('Already registered to wixCode messages')
        }
    }
}

WidgetAspect.hostPropTypes = {
    isMobileView: PropTypes.bool.isRequired,
    currentUrlPageId: PropTypes.string,
    hasWixCode: PropTypes.func.isRequired,
    isExperimentOpen: PropTypes.func.isRequired,
    initWixCode: PropTypes.bool.isRequired,
    isViewerMode: PropTypes.bool.isRequired,
    handleProcessedBehavior: PropTypes.func.isRequired,
    refreshRenderedContextsData: PropTypes.func.isRequired,
    getDataByQuery: PropTypes.func.isRequired,
    shouldUpdateRuntimeModels: PropTypes.func.isRequired,
    componentsFetcher: PropTypes.func.isRequired,
    RMIDataAPI: PropTypes.shape({
        RMI: PropTypes.shape({
            fetchData: PropTypes.func.isRequired,
            fetchSiteStructure: PropTypes.func.isRequired,
            fetchPageData: PropTypes.func.isRequired
        }),
        RGI: PropTypes.shape({
            fetchNavigationData: PropTypes.func,
            fetchSessionInfo: PropTypes.func,
            fetchSiteMemberData: PropTypes.func,
            fetchMultilingualInfo: PropTypes.func,
            fetchDeviceType: PropTypes.func.isRequired,
            fetchAppsData: PropTypes.func.isRequired
        })
    }).isRequired,
    resolveWidgetData: PropTypes.func.isRequired,
    routers: PropTypes.object.isRequired,
    setWarmupDataForController: PropTypes.func.isRequired,
    getRuntimeModels: PropTypes.func.isRequired,
    isFirstRenderAfterSSR: PropTypes.func.isRequired,
    getNavigationDataForRgi: PropTypes.func.isRequired,
    getSMbySiteExtensionInstanceForRgi: PropTypes.func.isRequired,
    getAppsDataForRgi: PropTypes.func.isRequired,
    getAllPossiblyRenderedContexts: PropTypes.func.isRequired,
    getApplicationsForContexts: PropTypes.func.isRequired,
    getUserCodeDefinitions: PropTypes.func,
    filterContextsToSync: PropTypes.func,
    filterValidContextsForLoad: PropTypes.func,
    filterContextsToActivate: PropTypes.func,
    getContextInitData: PropTypes.func.isRequired,
    getContextIdsWhichShouldBeRendered: PropTypes.func,
    getCompReactClass: PropTypes.func,
    wixCodePostMessagesProps: PropTypes.shape({
        preMessageHandlingHook: PropTypes.func,
        handleRequestAPIMessage: PropTypes.func,
        logsHandler: PropTypes.func,
        handleWixCodeAppApiMessage: PropTypes.func,
        hostAPIHandlers: PropTypes.object
    }),
    performanceLogger: PropTypes.shape({
        mark: PropTypes.func,
        measure: PropTypes.func
    }),
    deferWixCodeMessagesRegistration: PropTypes.bool,
    getAllRenderedContextIds: PropTypes.func.isRequired
}

WidgetAspect.defaultProps = {
    initWixCode: true,
    getContextInitData: () => ({}),
    getApplicationsForContexts: getUserCodeDefinitions,
    getUserCodeDefinitions,
    shouldUpdateRuntimeModels: () => false,
    setWarmupDataForController: _.noop,
    refreshRenderedContextsData: _.noop,
    performanceLogger: {
        mark: _.noop,
        measure: _.noop
    },
    getCompReactClass: () => () => {},
    componentsFetcher: () => ({}),
    RMIDataAPI: {
        RMI: {
            fetchData: _.noop,
            fetchSiteStructure: _.noop,
            fetchPageData: _.noop,
            fetchWidgetData: _.noop
        },
        RGI: {
            fetchNavigationData: () => ({
                currentPageFullUrl: window.location.href
            }),
            fetchSessionInfo: _.noop,
            fetchSiteMemberData: () => ({}),
            fetchMultilingualInfo: () => ({}),
            fetchDeviceType: _.noop,
            fetchAppsData: _.noop,
            fetchIsApplicationStudio: _.noop
        }
    },
    resolveWidgetData: _.identity,
    deferWixCodeMessagesRegistration: false,
    wixCodePostMessagesProps: {}
}
WidgetAspect.modelBuilderDataHelper = modelBuilderDataHelper
WidgetAspect.widgetDataResolvers = widgetDataResolvers

module.exports = WidgetAspect
